<template>
  <SignalSearchStyled :class="{ active: mobileIsActive }">
    <TrayExpanderStyled @click.stop="toggleSearchTray()">
      <ChevronLeftIcon v-if="!searchTrayExpanded" />
      <ChevronRightIcon v-else />
    </TrayExpanderStyled>
    <h2>{{ $t('signals.headers.search') }}</h2>
    <Multiselect
      :value="selectedAsset"
      @select="handleAssetSelect"
      :multiple="false"
      :allow-empty="false"
      :options="assets"
      :loading="assetsLoading"
      :showLabels="false"
      :placeholder="$tc('selects.asset', 1)"
      trackBy="id"
      label="name"
    />
    <template v-if="selectedAssetId">
      <SearchStyled>
        <input v-model="searchQuery" :placeholder="$t('signals.search.placeholder')" />
        <SearchIcon />
      </SearchStyled>
      <div class="loading" v-if="$apollo.loading">
        <LoadingDots />
      </div>
      <AvailableSignalsStyled v-else>
        <h2>{{ signalsFiltered.length }} / {{ signals.length }}</h2>
        <div class="signal-list">
          <AvailableSignal
            v-for="signal in signalsFiltered"
            :key="signal.signalId"
            :signal="signal"
            :selectedAsset="selectedAsset"
            :searchQueries="searchQueries"
          />
        </div>
      </AvailableSignalsStyled>
    </template>
  </SignalSearchStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { panel, flexColumns, flexCenter } from '@styles/mixins'
import { LoadingDots } from '@common/components'
import AvailableSignal from './SignalSearch/AvailableSignal'
import MobileArea from './mobileArea'
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from 'vue-feather-icons'
import { useStore } from '../../../stores/signals'
import { DateTime, Interval } from 'luxon'

import SIGNALS_BY_ASSET_QUERY from '#/graphql/signalsByAssetData/signalsByAsset.gql'

const SignalSearchStyled = styled(MobileArea)`
  ${panel}
  overflow: auto;
  @media (min-width: 768px) {
    grid-area: search;
    height: calc(100% - 9rem);
    max-height: calc(100% - 9rem);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem 3rem 4rem 1fr;
  }

  .loading {
    ${flexCenter}
    margin-top: 2rem;
  }

  .multiselect__tags {
    box-shadow: ${p => p.theme.colors.widgetShadow};
    border: 1px solid ${p => p.theme.colors.widgetBorder};
  }

  .multiselect__single {
    background: transparent;
    color: ${({ theme }) => theme.colors.normalFontColor};
  }

  .multiselect__option--selected {
    background: transparent;
  }

  .multiselect__content {
  }

  .multiselect__option {
    color: ${({ theme }) => theme.colors.normalFontColor};
  }

  .multiselect__placeholder {
    color: ${({ theme }) => theme.colors.normalFontColor};
  }

  .multiselect__option {
    color: ${({ theme }) => theme.colors.normalFontColor};
  }

  input {
    &:focus {
      background: transparent;
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.normalFontColor).alpha(0.5).css()};
    }
  }
`

export const SearchStyled = styled('div')`
  position: relative;
  width: 100%;
  padding: 1rem 0;
  input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0.75rem 1rem 0.75rem 2rem;
    color: ${props => props.theme.colors.primary};
    background: ${props => chroma(props.theme.colors.solidBG).darken(0.6).css()};
    border-bottom: 1px solid ${props => chroma(props.theme.colors.white).alpha(0.8).css()};
    transition: border-color 0.5s ease;

    &:focus {
      border-color: ${props => props.theme.colors.primary};
    }

    &::placeholder {
      color: ${props => chroma(props.theme.colors.primary).alpha(0.5).css()};
      font-size: 12px;
    }
  }
  .feather-search {
    position: absolute;
    top: 1.6rem;
    left: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
  }
`

const AvailableSignalsStyled = styled('div')`
  ${flexColumns}
  overflow-y: auto;
  position: relative;
  .signal-list {
    position: absolute;
    top: 2rem;
    width: 100%;
    height: calc(100% - 2rem);
  }
`
const TrayExpanderStyled = styled('div')`
  .feather {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    background-color: ${props => chroma(props.theme.colors.solidBG).darken(0.8).css()};
    border: solid 1px ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    margin: -0.7rem;
    width: 2.2rem;
    height: 2.2rem;
    position: fixed;
  }
  position: absolute;
`

export default {
  inject: ['uiSettings'],
  props: {
    assets: {
      type: Array,
      required: true,
    },
    assetsLoading: {
      type: Boolean,
      required: true,
    },
    mobileIsActive: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const signalStore = useStore()
    return {
      signalStore,
    }
  },
  components: {
    SignalSearchStyled,
    Multiselect,
    SearchStyled,
    SearchIcon,
    AvailableSignal,
    AvailableSignalsStyled,
    LoadingDots,
    ChevronLeftIcon,
    ChevronRightIcon,
    TrayExpanderStyled,
  },
  data() {
    return {
      signals: [],
      searchQuery: '',
      signalSelectedAsset: null,
      searchTrayExpanded: false,
    }
  },
  destroyed() {
    this.resetSelectedSignals()
  },

  computed: {
    locale() {
      const supportedLocales = ['EN_GB', 'DE_DE']
      const userLocale = this.uiSettings?.dates ?? ''
      return supportedLocales.includes(userLocale) ? userLocale.slice(0, 2).toLowerCase() + userLocale.slice(2, 5) : ''
    },
    selectedAssetId() {
      return this.$route?.query?.assetId
    },
    selectedAsset() {
      const selectedAsset = this.assets.find(f => f.id === this.selectedAssetId)
      if (selectedAsset) {
        this.$matomo?.trackEvent('Signals', 'Machine Added', selectedAsset?.name, 0)
      }
      return selectedAsset
    },
    selectedSignalIds() {
      return this.$route?.query?.signalIds
    },
    selectedStartTime() {
      const start = this.$route?.query?.start
      return start ? DateTime.fromMillis(start * 1).toISO() : ''
    },

    searchQueries() {
      return this.searchQuery.split(' ')
    },
    signalsFiltered() {
      if (!this.signals) {
        return []
      }
      const qWords = this.searchQueries
      const avail = this.signals
      if (avail.length < 1 || qWords.length < 1 || qWords === '' || (qWords.length === 1 && qWords[0] === '')) {
        return avail.sort((a, b) => a.translatedName.localeCompare(b.translatedName))
      }
      return avail
        .map(a => {
          let matchCount = 0
          qWords.forEach(word => {
            const regex = new RegExp(word, 'ig')
            matchCount += (a.translatedName.match(regex) || a.longName.match(regex) || []).length
          })
          return {
            signal: a,
            score: matchCount,
          }
        })
        .filter(item => item.score > 0)
        .sort((a, b) => b.score - a.score)
        .map(item => item.signal)
    },
  },
  methods: {
    handleAssetSelect({ id }) {
      this.$router.push({
        query: {
          ...this.$route.$query,
          assetId: id,
        },
      })
    },
    toggleSearchTray() {
      this.searchTrayExpanded = !this.searchTrayExpanded
      this.$emit('searchExpanded', this.searchTrayExpanded)
    },
    resetSelectedSignals() {
      this.signalStore.$reset()
    },
    addSelectedSignalsToStore(selectedSignals) {
      const end = DateTime.fromISO(this.selectedStartTime).endOf('hour').plus(1)
      const start = DateTime.fromISO(this.selectedStartTime).startOf('hour')
      const selectedInterval = Interval.fromDateTimes(start, end).toISO()
      selectedSignals.forEach(signal => {
        this.$matomo?.trackEvent('Signals', 'Signal Selected', `${signal.shortName} - ${signal.signalId} - ${this.selectedAsset.name}`, 0)
        this.signalStore.selectSignal(signal, this.selectedAsset, selectedInterval)
      })
    },
  },
  apollo: {
    signals: {
      query: SIGNALS_BY_ASSET_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          assetId: this.selectedAssetId,
          locale: this.locale,
        }
      },
      skip() {
        return !this.selectedAssetId || this.assetsLoading
      },
      update: ({ signalsByAsset }) => signalsByAsset,
      result({ data }) {
        if (data) {
          const selectedSignals = data?.signalsByAsset.filter(e => this.selectedSignalIds?.includes(e.signalId))
          if (selectedSignals && this.selectedSignalIds) {
            /** Adding this to reset the current selected signals selection in the store */
            this.resetSelectedSignals()
            this.addSelectedSignalsToStore(selectedSignals)
          }
        }
      },
    },
  },
}
</script>
